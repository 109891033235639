import CONFIG from 'config';
import { merge } from 'lodash';
import ANALYTICS from 'utils/analytics/analytics';

const naviPageInfo = {
  pageInfo: {
    pageName: CONFIG.LABELS.NAVI
  },
  category: {
    primaryCategory: CONFIG.LABELS.NAVI
  }
};

export const chatStreamAnalytics = (chatID, threadID, time=null, chatHistoryId='N/A') => {
  _satellite.track('chatbot_send');
  const adobe = {
    chatID,
    threadID,
    page: naviPageInfo,
    officePage: {
      link: CONFIG.LABELS.ENTER_CHAT,
    },
    responseHistoryID: chatHistoryId,
  };
  if (time) {
    adobe.timeFirstToken = `${time}ms`;
  }
  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const sendMessageAnalytics = (enterChat, chatHistoryId='N/A')=> {
  const adobe = {
    page: naviPageInfo,
    officePage: { link: enterChat },
    responseHistoryID: chatHistoryId,
  };
  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const stopGeneration = (chatID, threadID, chatHistoryId) => {
  const adobe = {
    chatID,
    threadID,
    page: naviPageInfo,
    officePage: {
      link: 'Stop Generation',
    },
    responseHistoryID: chatHistoryId
  };
  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const timeToCompleteChat = (chatID='N/A', threadID='N/A', timeToFirstChunk, timeToComplete, chatHistoryId) => {
  const adobe = {
    chatID,
    threadID,
    page: naviPageInfo,
    officePage: {
      link: 'Chat Complete',
    },
    timeFirstToken: `${timeToFirstChunk}ms`,
    timeToComplete,
    responseHistoryID: chatHistoryId,
  };

  ANALYTICS.page.trackEvent('time_to_complete', adobe);
};

export const submitChatFeedback = ({ chatId, requestId, sentiment }, userFeedback) => {
  const adobe = {
    chatID: chatId,
    threadID: requestId,
    resultExplicitFeedback: userFeedback,
    displayMode: 'chat',
    SERPview: 'chat view',
    page: naviPageInfo,
    selectedOptionReason: userFeedback,
    materialID: 'not available',
    resultType: 'not available',
    materialURL: 'not available',
    globalRelevance: 'not available',
    materialPAStatus: 'not available',
    attachmentID: 'not available',
    searchResultRanking: 'not available',
    resultRelevanceRating: sentiment,
    labCourseID: 'not available',
    labCourseName: 'not available',
    labLessonID: 'not available',
    labLessonName: 'not available',
    caseID: 'not available',
    KCPage: {
      id: 'not available',
    }
  };
  const appDefaults = ANALYTICS.page.getAppDefaults();
  const expertsDefaults = ANALYTICS.expertsDefaults.getDefaultQueryProps();
  const chatAdobe = merge({}, appDefaults, expertsDefaults, adobe);
  ANALYTICS.page.trackEvent('result_explicit_feedback', chatAdobe);
};

export const fetchChatHistoryClick = (chatId, requestId, chatHistoryId) => {
  const adobe = {
    chatID: chatId,
    threadID: requestId,
    page: naviPageInfo,
    officePage: {
      link: 'Chat History',
    },
    responseHistoryID: chatHistoryId
  };
  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const copyChatMsgBtnClick = (chatID, threadID, chatHistoryId) => {
  const adobe = {
    chatID,
    threadID,
    officePage: {
      link: 'copy',
    },
    page: naviPageInfo,
    responseHistoryID: chatHistoryId
  };

  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const navigationAnalytics = (type, chatID, threadID, chatHistoryId) => {
  const adobe = {
    chatID,
    threadID,
    officePage: {
      link: type,
    },
    page: naviPageInfo,
    responseHistoryID: chatHistoryId
  };

  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const expandMoreAnalytics = (type, chatID, threadID, chatHistoryId='N/A') => {
  const adobe = {
    chatID,
    threadID,
    officePage: {
      link: type,
    },
    page: naviPageInfo,
    responseHistoryID: chatHistoryId
  };

  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const naviErrorAnalytics = (error, chatID, threadID, chatHistoryId='N/A') => {
  const adobe = {
    chatID,
    threadID,
    page: {
      pageInfo: {
        pageName: 'Navi Error',
      },
      category: {
        primaryCategory: CONFIG.LABELS.NAVI,
      },
    },
    officePage: {
      link: error,
    },
    responseHistoryID: chatHistoryId
  };

  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};

export const promptTemplateAnalytics = (chatHistoryId='N/A') => {
  const adobe = {
    page: naviPageInfo,
    officePage: {
      link: 'Prompt Templates',
    },
    responseHistoryID: chatHistoryId
  };

  ANALYTICS.page.trackEvent('Navigator_Button_Click', adobe);
};